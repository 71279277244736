import React, { forwardRef } from 'react'
import Logo from '@/assets/couponLogo.webp'
import styles from './index.module.scss'
import dynamic from "next/dynamic";
const HeaderNav = dynamic(()=>import('../Nav/HeaderNav'))
const HeaderSearch = dynamic(()=>import('../Search/HeaderSearch'))
export interface HeaderType {
  header: {
    logo: string
    menus: Array<{
      name: string
      path: string
    }>
    searchPlaceholder: string
    isMobile: boolean
  }
}


const HeaderMobileNav = dynamic(()=>import("@/components/MobileNav/HeaderMobileNav"),{
  // ssr: false
})

export default forwardRef(function Header({ header, isMobile }: any, ref) {
  return (
    <header
      ref={ref as React.ForwardedRef<HTMLElement>}
      className={styles.header}
    >
      <div className={styles.header_wrapper}>
        <a href="/" className={styles.logo_wrapper}>
          <img
            src={Logo.src}
            // src={header.logo ?? ''}
            alt="logo"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </a>
        {
          !isMobile &&
        <HeaderNav menu={header.menus ?? []}></HeaderNav>
        }
        <HeaderSearch
          searchPlaceholder={header.searchPlaceholder ?? ''}
        ></HeaderSearch>

        <HeaderMobileNav
          logo={header.logo}
          menu={header.menus ?? []}
          isMobile={isMobile}
        ></HeaderMobileNav>
      </div>
    </header>
  )
})
