import React, {useEffect, useRef, useState} from 'react'
import Header from '../Header'
import styles from './index.module.scss'
import dynamic from "next/dynamic";

const Footer = dynamic(() => import('@/components/Footer'), {
  // ssr: false
})
const GoTop = dynamic(() => import('@/components/GoTop'))

export default function Layout({
                                 pageInfo,
                                 children,
                                 isMobile,
                                 grayBg = true,
                               }: {
  pageInfo: any
  children: React.ReactNode,
  isMobile?: Boolean
  grayBg?: Boolean
}) {
  const headerRef = useRef<HTMLElement>()
  const [isShow, setIsShow] = useState(false)

  const handleScroll = () => {
    if (headerRef.current && headerRef.current!.offsetTop > 100) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }

  const handleClick = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (isMobile) return
    if (headerRef.current!.offsetTop > 0) {
      setIsShow(true)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={grayBg ? styles.layout : ''}>
      <Header ref={headerRef} header={pageInfo && pageInfo.header} isMobile={isMobile}></Header>
      <div>{children}</div>
      <Footer
        footer={pageInfo && pageInfo.footer}
      ></Footer>
      {isShow && <GoTop handleClick={handleClick}></GoTop>}
    </div>
  )
}
